$(document).on('click', '#carousel-list .carousel-list-item', setCarouselIndex);
$(document).on('click', '#galery-carousel-left', prevGalery);
$(document).on('click', '#galery-carousel-right', nextGalery);
/////////////////////////////////////
var carouselArray = [];
var carouselIndex = 0;
var companyGaleryArray = [];
var carouselInterval = setInterval(nextCarousel, 60000);
$(document).ready(function () {
    getCarousel();
    getGalery();
    getClients();
    getRelatedProducts();
    ////////Company action buttons
    $('#about-us-list .about-us-list-item').hover(aboutUsHover, aboutUsLeave);
});

function getCarousel() {
    GetMethodFunction('/WebPage/HomeCarrousel/Get', null, showCarousel, null);
}

function showCarousel(response) {
    carouselArray = response.data;
    let AppendContent = '';
    for (let index = 0; index < carouselArray.length; index++) {
        AppendContent += '<li class="carousel-list-item" id="carousel-list-item-' + index + '" value="' + index + '" loading="lazy"></li>';
    }
    $('#carousel-list').empty().append(AppendContent);
    showCarouselIndex();
}

function nextCarousel() {
    carouselIndex++;
    if (carouselIndex >= carouselArray.length) {
        carouselIndex = 0;
    }
    showCarouselIndex();
}

function prevCarousel() {
    carouselIndex--;
    if (carouselIndex < 0) {
        carouselIndex = carouselArray.length - 1;
    }
    showCarouselIndex();
}

function setCarouselIndex() {
    clearInterval(carouselInterval);
    carouselIndex = $(this).attr('value');
    showCarouselIndex();
}

function showCarouselIndex() {
    var carousel = carouselArray[carouselIndex];
    if (carousel.title == null || carousel.title == '') {
        $('#carousel-text-title').css('display', 'none');
    } else {
        $('#carousel-text-title').css('display', 'block');
        $('#carousel-text-title').text(carousel.title);
    }
    if (carousel.subtitle == null || carousel.subtitle == '') {
        $('#carousel-text-subtitle').css('display', 'none');
    } else {
        $('#carousel-text-subtitle').css('display', 'block');
        $('#carousel-text-subtitle').text(carousel.subtitle);
    }
    if (carousel.button_text == null || carousel.button_text == '') {
        $('#carousel-text-action').css('display', 'none');
    } else {
        $('#carousel-text-action').css('display', 'block');
        $('#carousel-text-action').text(carousel.button_text);
        $('#carousel-text-action').attr('href', carousel.button_url);
    }
    $('#carousel-image-container').css('background-image', 'url(' + carousel.path + ')').removeClass('carousel-image-0 carousel-image-1').addClass('carousel-image-' + carousel.image_position);
    $('#carousel-container').css('background-image', 'url(' + carousel.path + ')');
    if (carousel.image_position == 0) {
        $('#carousel-container').removeClass('carousel-image-1').addClass('carousel-image-0');
    } else {
        $('#carousel-container').removeClass('carousel-image-0').addClass('carousel-image-1');
    }

    $('#carousel-list .carousel-list-item').removeClass('active');
    $('#carousel-list-item-' + carouselIndex).addClass('active');
}

function aboutUsHover() {
    $(this).find('.about-us-img').css('display', 'none');
    $(this).find('.about-us-title').css('display', 'none');
    $(this).find('.about-us-paragraph').css('display', 'block');
}

function aboutUsLeave() {
    $(this).find('.about-us-img').css('display', 'block');
    $(this).find('.about-us-title').css('display', 'block');
    $(this).find('.about-us-paragraph').css('display', 'none');
}

function getGalery() {
    GetMethodFunction('/WebPage/Company/Galery/Get', null, showGalery, null);
}

function showGalery(response) {
    companyGaleryArray = response.data;
    setGaleryImages();
}

function setGaleryImages() {
    if (companyGaleryArray[0] != null) $('#galery-carousel-list .galery-carousel-list-item-0').css('background-image', 'url(' + companyGaleryArray[0].path + ')');
    if (companyGaleryArray[1] != null) $('#galery-carousel-list .galery-carousel-list-item-1').css('background-image', 'url(' + companyGaleryArray[1].path + ')');
    if (companyGaleryArray[2] != null) $('#galery-carousel-list .galery-carousel-list-item-2').css('background-image', 'url(' + companyGaleryArray[2].path + ')');
    if (companyGaleryArray[3] != null) $('#galery-carousel-list .galery-carousel-list-item-3').css('background-image', 'url(' + companyGaleryArray[3].path + ')');
    if (companyGaleryArray[4] != null) $('#galery-carousel-list .galery-carousel-list-item-4').css('background-image', 'url(' + companyGaleryArray[4].path + ')');

}

function nextGalery() {
    let last = companyGaleryArray.length - 1;
    let temp = companyGaleryArray[last];
    for (let index = last; index > 0; index--) {
        companyGaleryArray[index] = companyGaleryArray[index - 1];
    }
    companyGaleryArray[0] = temp;
    setGaleryImages();
}

function prevGalery() {
    let temp = companyGaleryArray[0];
    for (let index = 0; index < companyGaleryArray.length - 1; index++) {
        companyGaleryArray[index] = companyGaleryArray[index + 1];
    }
    companyGaleryArray[companyGaleryArray.length - 1] = temp;
    setGaleryImages();
}

function getClients() {
    GetMethodFunction('/WebPage/Client/GetAll', null, showClients, null);
}

function showClients(response) {
    let clients = response.clients;
    let AppendContent = '';
    if (clients[0] != null) AppendContent += '<img src="' + clients[0].path + '" title="' + clients[0].name + '" id="clients-images-1" alt="client-1" class="clients-images" loading="lazy">';
    if (clients[1] != null) AppendContent += '<img src="' + clients[1].path + '" title="' + clients[1].name + '" id="clients-images-2" alt="client-2" class="clients-images" loading="lazy">';
    if (clients[2] != null) AppendContent += '<img src="' + clients[2].path + '" title="' + clients[2].name + '" id="clients-images-3" alt="client-3" class="clients-images" loading="lazy">';
    if (clients[3] != null) AppendContent += '<img src="' + clients[3].path + '" title="' + clients[3].name + '" id="clients-images-4" alt="client-4" class="clients-images" loading="lazy">';
    if (clients[4] != null) AppendContent += '<img src="' + clients[4].path + '" title="' + clients[4].name + '" id="clients-images-5" alt="client-5" class="clients-images" loading="lazy">';
    if (clients[5] != null) AppendContent += '<img src="' + clients[5].path + '" title="' + clients[5].name + '" id="clients-images-6" alt="client-6" class="clients-images" loading="lazy">';
    if (clients[6] != null) AppendContent += '<img src="' + clients[6].path + '" title="' + clients[6].name + '" id="clients-images-7" alt="client-7" class="clients-images" loading="lazy">';
    if (clients[7] != null) AppendContent += '<img src="' + clients[7].path + '" title="' + clients[7].name + '" id="clients-images-8" alt="client-8" class="clients-images" loading="lazy">';
    if (clients[8] != null) AppendContent += '<img src="' + clients[8].path + '" title="' + clients[8].name + '" id="clients-images-9" alt="client-9" class="clients-images" loading="lazy">';
    if (clients[9] != null) AppendContent += '<img src="' + clients[9].path + '" title="' + clients[9].name + '" id="clients-images-10" alt="client-10" class="clients-images" loading="lazy">';
    if (clients[10] != null) AppendContent += '<img src="' + clients[10].path + '" title="' + clients[10].name + '" id="clients-images-11" alt="client-11" class="clients-images" loading="lazy">';
    if (clients[11] != null) AppendContent += '<img src="' + clients[11].path + '" title="' + clients[11].name + '" id="clients-images-12" alt="client-12" class="clients-images" loading="lazy">';
    if (clients[12] != null) AppendContent += '<img src="' + clients[12].path + '" title="' + clients[12].name + '" id="clients-images-13" alt="client-13" class="clients-images" loading="lazy">';
    if (clients[13] != null) AppendContent += '<img src="' + clients[13].path + '" title="' + clients[13].name + '" id="clients-images-14" alt="client-14" class="clients-images" loading="lazy">';
    if (clients[14] != null) AppendContent += '<img src="' + clients[14].path + '" title="' + clients[14].name + '" id="clients-images-15" alt="client-15" class="clients-images" loading="lazy">';
    $('#clients-images-container').empty().html(AppendContent);
}

function getRelatedProducts() {
    let data = {
        pagination: {
            page: 1,
            size: 3,
            total: 0,
            totalPages: 0
        },
        category_ids: [],
        brand_ids: ['all'],
        order_id: 'relevance',
        stock_id: null,
        search: '',
        exclude_ids: []
    };
    PostMethodFunction('/WebPage/Catalogue/Product/GetPage', data, null, showRelatedProducts, function () {});
}

function showRelatedProducts(response) {
    products_array = response.data;
    let appendContent = '';
    let product_image_path = '';
    $.each(products_array, function (index, item) {
        appendContent += '<li class="product-list-item" product-id="' + item.id + '">';
        if (item.images.length == 0) {
            product_image_path = '/images/product/no-image.png';
        } else {
            //select random image path
            product_image_path = item.images[Math.floor(Math.random() * item.images.length)].path;
        }
        appendContent += '<div class="product-list-item-image" style="background-image: url(\'/' + product_image_path + '\');"></div>';
        appendContent += '<h2 class="product-list-item-title">' + item.name + '</h2>';
        appendContent += '<div class="product-list-item-categories-container mb-0 mt-auto">';
        $.each(item.categories, function (index, category) {
            appendContent += '<p class="product-list-item-category">' + category.name + (index == item.categories.length - 1 ? '.' : ',') + '</p>';
        });
        appendContent += '</div>';
        appendContent += '<div class="product-list-item-sizes-container my-0">';
        $.each(item.sizes, function (index, size) {
            appendContent += '<span class="product-list-item-size badge ' + (index == 0 ? 'selected' : 'disabled') + '" size-id="' + size.id + '">' + size.size + '</span>';
        });
        appendContent += '</div>';
        appendContent += '<div class="product-list-item-price-container d-flex justify-content-start my-0">';
        if (item.has_discount == true) appendContent += '<span class="align-self-center product-list-item-price-discount"></span>';
        appendContent += '<span class="align-self-center product-list-item-price"></span>';
        appendContent += '</div>';
        appendContent += '<div class="product-list-item-buttons-container d-flex justify-content-between my-0">';
        appendContent += '<a href="/products/' + item.url + '" class="product-list-item-view d-flex justify-content-center"><i class="fa-solid fa-eye align-self-center"></i></a>';
        appendContent += '<div class="product-list-item-quantity-container d-flex justify-content-between">';
        appendContent += '<i class="fa-solid fa-chevron-down align-self-center quantity-down"></i>';
        appendContent += '<input type="number" class="text-center align-self-center product-list-item-input" value="1" min="1" title="Cantidad">';
        appendContent += '<i class="fa-solid fa-chevron-up align-self-center quantity-up"></i>';
        appendContent += '</div>';
        appendContent += '<button class="product-list-item-shopping-cart d-flex justify-content-center add-to-shopping-cart" title="Ver"><i class="fa-solid fa-cart-shopping align-self-center"></i></button>';
        appendContent += '</div>';
        appendContent += '</li>';
    });
    $('#related-products-list').html(appendContent);
    $.each($('#related-products-list .product-list-item'), function (index, item) {
        showProductPrice($(item));
    });
}
function showProductPrice(container){
    let product_id = container.attr('product-id');
    let selected_size = container.find('.product-list-item-size.selected');
    //get product size info
    let product = products_array.find(x => x.id == product_id);
    if(product == null) return;
    let product_size = product.sizes.find(x => x.id == selected_size.attr('size-id'));
    if(product_size == null) return;
    if(product.has_discount == true){
      if(product_size.original_price==null || product_size.original_price==undefined){
        product_size.original_price = product_size.price;
      }
      product_size.price = product_size.price - (product_size.price * (product.discount.discount/100));
      container.find('.product-list-item-price-discount').html('$'+product_size.original_price.toLocaleString().replace(',', '.')+' COP');
      container.find('.product-list-item-price').html('$'+product_size.price.toLocaleString().replace(',', '.')+' COP');
    }else{
        container.find('.product-list-item-price-discount').html('');
        container.find('.product-list-item-price').html('$'+product_size.price.toLocaleString().replace(',', '.')+' COP');
    }
    
  }